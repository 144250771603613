/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

import { observable } from "mobx";

export const busyDelayMillis = 500;

// Delays setting "isWorkingDelayed" property by "busyDelayMillis" after "inFlow" counter is incremented from 0 to 1
export class FlowBusyMonitor {
  @observable _inFlow = 0;
  @observable isWorkingDelayed = false;
  @observable isWorking = false;
  private flowLeft = false;

  set inFlow(value: number) {
    if (value < 0) {
      value = 0;
    }
    if (this._inFlow === 0 && value === 1) {
      this.flowLeft = false;

      this.isWorking = true
      setTimeout(() => {
        if (!this.flowLeft) {
          this.isWorkingDelayed = true
        }
      }, busyDelayMillis);
    } else if (this._inFlow === 1 && value === 0) {
      this.isWorkingDelayed = false;
      this.isWorking = false;
      this.flowLeft = true;
    }
    this._inFlow = value;
  }

  get inFlow() {
    return this._inFlow;
  }
}