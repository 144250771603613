/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

import { csToMomentFormat } from "@origam/utils";
import { T } from "../utils/translation";
import { getDefaultCsDateFormatDataFromCookie } from "../utils/cookies";

export function replaceDefaultDateFormats(formatFromServer: string) {
  if (formatFromServer === "long") {
    let defaultDateFormatData = getDefaultCsDateFormatDataFromCookie();
    return defaultDateFormatData.defaultLongDateFormat;
  }

  if (formatFromServer === "short") {
    let defaultDateFormatData = getDefaultCsDateFormatDataFromCookie();
    return defaultDateFormatData.defaultShortDateFormat;
  }

  if (formatFromServer === "time") {
    let defaultDateFormatData = getDefaultCsDateFormatDataFromCookie();
    return defaultDateFormatData.defaultTimeFormat;
  }
  return formatFromServer;
}


export function getMomentFormat(propertyXmlNode: any) {
  const nodeAttributes = propertyXmlNode.attributes;
  const csFormat = replaceDefaultDateFormats(nodeAttributes.FormatterPattern);
  const momentFormat = csToMomentFormat(csFormat)
  if (!momentFormat) {
    throw new Error(T("CustomFormat \"{0}\" of the field named: \"{1}\", id: \"{2}\" is not valid",
      "invalid_field_format",
      nodeAttributes.FormatterPattern, nodeAttributes.Name, nodeAttributes.ModelInstanceId));
  }
  return momentFormat;
}