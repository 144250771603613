/*
Copyright 2005 - 2021 Advantage Solutions, s. r. o.

This file is part of ORIGAM (http://www.origam.org).

ORIGAM is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

ORIGAM is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with ORIGAM. If not, see <http://www.gnu.org/licenses/>.
*/

import { RefObject } from "react";

export enum IMainMenuItemType {
  FormRef = "FormReferenceMenuItem",
  FormRefWithSelection = "FormReferenceMenuItem_WithSelection",
  ReportRefWithSelection = "ReportReferenceMenuItem_WithSelection",
  WorkflowRef = "WorkflowReferenceMenuItem",
  WorkQueue = "WorkQueue",
  ReportReferenceMenuItem = "ReportReferenceMenuItem"
}

export interface IMainMenuData {
  menuUI: any;
}

export interface IMainMenuContent extends IMainMenuData {
  $type_IMainMenuContent: 1;

  getItemById(id: string): any;

  parent?: any;
}

export interface IMainMenuEnvelope {
  $type_IMainMenuEnvelope: 1;

  mainMenu?: IMainMenu;
  isLoading: boolean;

  setMainMenu(mainMenu: IMainMenuContent | undefined): void;

  setLoading(state: boolean): void;

  parent?: any;
}


export interface IMainMenuState {
  editing: boolean;
  highLightedItemId: string | undefined;

  highlightItem(itemId: string): void;

  closeAll(): void;

  isOpen(menuId: string): boolean;

  setIsOpen(menuId: string, state: boolean): void;

  flipIsOpen(menuId: string): void;

  setReference(id: string, ref: RefObject<HTMLElement>): void;

  scrollToItem(id: string): void
}

export type IMainMenu = IMainMenuEnvelope & IMainMenuContent;

export const isIMainMenuContent = (o: any): o is IMainMenuContent => o.$type_IMainMenuContent;
export const isIMainMenuEnvelope = (o: any): o is IMainMenuEnvelope =>
  o.$type_IMainMenuEnvelope;

